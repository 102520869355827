<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.fiscal_year') }}</b-th>
                                        <b-td class="text-capitalize"> {{ localeCheck === 'bn' ? allocation.fiscal_year_bn : allocation.fiscal_year }} </b-td>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.season') }}</b-th>
                                        <b-td class="text-capitalize"> {{ localeCheck === 'bn' ? allocation.season_bn : allocation.season }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_config.circular_name') }}</b-th>
                                        <b-td class="text-capitalize">{{ localeCheck === 'bn' ? allocation.circular_name_bn : allocation.circular_name }}</b-td>
                                        <b-th>{{ $t('dae_config.crop_name') }}</b-th>
                                        <b-td class="text-capitalize">{{ localeCheck === 'bn' ? allocation.crop_name_bn : allocation.crop_name }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <h4>{{ $t('budget.forwardComments') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('budget.sendFrom') }}</b-th>
                                        <b-th>{{ $t('budget.sendTo') }}</b-th>
                                        <b-th>{{ $t('budget.comment') }}</b-th>
                                    </b-tr>
                                    <template v-if="allocation.forward.length">
                                      <b-tr v-for="(forward,index) in allocation.forward" :key="index">
                                          <b-td>{{ $n(index+1) }}</b-td>
                                          <b-td>{{ getUserName(forward.sender_id) }}</b-td>
                                          <b-td>{{ getUserName(forward.receiver_id) }}</b-td>
                                          <b-td>{{ forward.comment }}</b-td>
                                      </b-tr>
                                    </template>
                                    <template v-else>
                                      <b-tr>
                                        <b-td align="center" colspan="4">{{ $t('globalTrans.noDataFound') }}</b-td>
                                      </b-tr>
                                    </template>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['item'],
    data () {
        return {
            loading: false,
            allocation: '',
            search: {},
            users: []
        }
    },
    created () {
        this.allocation = this.item
        this.getForwardedUsers(this.allocation.forward)
    },
    computed: {
        localeCheck: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        async getForwardedUsers (forwards) {
            this.loading = true
            var userIds = []
            forwards.map((item, key) => {
                userIds.push(item.sender_id)
                userIds.push(item.receiver_id)
            })

            const params = Object.assign({}, this.search, { user_ids: userIds })
            await RestApi.getData(authServiceBaseUrl, '/user/user-list', params).then(response => {
                if (response.success) {
                    this.users = response.data
                } else {
                    this.users = []
                }
                this.loading = false
            })
        },
        getStatus (status) {
            if (status === 1) {
                return this.$t('budget.pending')
            } else if (status === 2) {
                return this.$t('budget.forward')
            } else if (status === 3) {
                return this.$t('budget.approve')
            } else if (status === 4) {
                return this.$t('budget.reject')
            }
        },
        getUserName (userId) {
            const user = this.users.find(user => user.value === userId)
            return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
        }
    }
}
</script>
