
<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('incentiveAllocation.district_allocation') }}</h4>
          </template>
          <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.fiscal_year')"
                      label-for="fiscal_year_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('dae_config.season_name')"
                      label-for="season_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.season_id"
                          :options="seasonSetupList"
                          id="season_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('dae_subsidy.circular_type')"
                    label-for="circular_type"
                    >
                    <b-form-select
                        plain
                        v-model="search.circular_type"
                        :options="circularTypeList"
                        id="circular_type"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('dae_config.circular_name')"
                    label-for="circular_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.circular_id"
                        :options="circularList"
                        id="circular_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('dae_config.crop_name')"
                    label-for="crop_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.crop_id"
                        :options="cropList"
                        id="crop_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
          </template>
        </iq-card>
        <b-row>
            <b-col md="12">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('incentiveAllocation.district_allocation') }} {{ $t('globalTrans.approved') }} {{ $t('globalTrans.list') }}</h4>
                </template>
                <template v-slot:body>
                  <b-overlay :show="loadingState">
                      <b-row>
                          <b-col md="12" class="table-responsive">
                              <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                  <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                  <template v-slot:cell(total_lands)="data">
                                      {{  $n(data.item.total_lands) }}
                                  </template>
                                    <template v-slot:cell(Total_farmer)="data">
                                      {{  $n(data.item.Total_farmer) }}
                                  </template>
                                <template v-slot:cell(status)="data">
                                  {{ getStatus(data.item.status) }}
                                </template>
                                  <template v-slot:cell(action)="data">
                                    <a href="javascript:" class="btn_table_action table_action_status" title="Comment" v-b-modal.modal-3 @click="forwardComment(data.item)"><i class="far fa-comment-dots"></i></a>
                                    <a href="javascript:" class="btn_table_action table_action_view" title="Allocation Details" v-b-modal.modal-5 size="sm" @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                  </template>
                              </b-table>
                              <b-pagination
                                align="center"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                              />
                          </b-col>
                      </b-row>
                  </b-overlay>
                </template>
              </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="xl" :title="$t('incentiveAllocation.allocation_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item.id"></Details>
        </b-modal>
        <b-modal id="modal-3" size="lg" :title="$t('budget.forwardComment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <ForwardComment :item="item" :key="item.id"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from '../DistricAllocationDetails'
import ForwardComment from './ForwardComment'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distAlloList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, ForwardComment
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        circular_id: 0,
        circular_type: 0,
        crop_id: 0,
        status: 3
      },
      rows: [],
      circularList: [],
      cropList: [],
      item: '',
      itemId: 0,
      status: 0,
      approveTitle: ''
    }
  },
  watch: {
    'search.circular_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.circularList = this.getCircularList(newVal)
      }
    },
    'search.circular_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cropList = this.getCircularCropList(newVal)
      }
    }
  },
  computed: {
    authUser: function () {
      return this.$store.state.Auth.authUser
    },
    fiscalList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    seasonSetupList: function () {
      return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn, text_bn: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en, text_en: item.text_en }
        }
      })
    },
    formTitle () {
      return this.$t('incentiveAllocation.district_allocation') + ' ' + this.$t('globalTrans.entry')
    },
    circularTypeList: function () {
      const dataList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.filter(ct => ct.value !== 1 && ct.value !== 4)
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('dae_grant_allocation_distribution.fiscal_year'), class: 'text-center' },
          { label: this.$t('dae_grant_allocation_distribution.season'), class: 'text-center' },
          { label: this.$t('dae_subsidy.circular_type'), class: 'text-center' },
          { label: this.$t('dae_config.circular_name'), class: 'text-center' },
          { label: this.$t('bsri_config.project_name'), class: 'text-center' },
          { label: this.$t('dae_config.crop_name'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'season_bn' },
          { key: 'circular_type_bn' },
          { key: 'circular_name_bn' },
          { key: 'project_name_bn' },
          { key: 'crop_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'seasson' },
          { key: 'circular_type_en' },
          { key: 'circular_name' },
          { key: 'project_name' },
          { key: 'crop_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    getCircularList (circularType) {
      const dataLlist = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.circular_type === circularType && item.fiscal_year_id === this.search.fiscal_year_id)
      return dataLlist.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    getCircularCropList (circularId) {
      const dataLlist = this.$store.state.incentiveGrant.commonObj.circularCropList.filter(item => item.status === 1 && item.circular_id === circularId)
      return dataLlist.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    loadData () {
      let params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      if (this.authUser.role_id !== 1) {
        params = Object.assign({}, params, { office_id: this.authUser.office_id })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, distAlloList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList
      const circularTypeList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
      const projectList = this.$store.state.incentiveGrant.commonObj.projectList
      const cropList = this.$store.state.incentiveGrant.commonObj.cropList
      const listData = data.map(item => {
        const fiscalObject = fiscalYearList.find(agmt => agmt.value === item.fiscal_year_id)
        const circularObj = circularList.find(cr => cr.value === item.circular_id)
        const circularTypeObj = circularTypeList.find(crt => crt.value === circularObj.type)
        const projectObj = projectList.find(project => project.value === circularObj.project_id)
        const seasonObject = this.seasonSetupList.find(agmt => agmt.value === item.season_id)
        const cropObj = cropList.find(crop => crop.value === item.crop_id)

        const fiscalyearData = {
          fiscal_year: fiscalObject !== undefined ? fiscalObject.text_en : '',
          fiscal_year_bn: fiscalObject !== undefined ? fiscalObject.text_bn : ''
        }
        const circularData = {
          circular_name: circularObj !== undefined ? circularObj.text_en : '',
          circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
        }
        const circularTypeData = {
          circular_type_en: circularTypeObj !== undefined ? circularTypeObj.text_en : '',
          circular_type_bn: circularTypeObj !== undefined ? circularTypeObj.text_bn : ''
        }
        const projectData = {
          project_name: projectObj !== undefined ? projectObj.text_en : '',
          project_name_bn: projectObj !== undefined ? projectObj.text_bn : ''
        }
        const seasonData = {
          seasson: seasonObject !== undefined ? seasonObject.text_en : '',
          season_bn: seasonObject !== undefined ? seasonObject.text_bn : ''
        }
        const cropData = {
          crop_name: cropObj !== undefined ? cropObj.text_en : '-',
          crop_name_bn: cropObj !== undefined ? cropObj.text_bn : '-'
        }

        return Object.assign({}, item, fiscalyearData, circularData, circularTypeData, projectData, seasonData, cropData)
      })
      return listData
    },
    forwardComment (item) {
      this.item = item
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('budget.forward')
      } else if (status === 3) {
        return this.$t('budget.approve')
      } else if (status === 4) {
        return this.$t('budget.reject')
      }
    },
    details (item) {
      this.item = item
    }
  }
}
</script>
